<template>
  <portal to="app-header">
    ML Models
  </portal>
</template>

<script>
export default {
  name: 'ModelManagementHeader',
};
</script>
